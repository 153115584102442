
// mui
import { useTheme } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';


interface Props {
    open: boolean,
    handleDrawerOpen: () => void
}

export const RightPanelOpen = ({ open, handleDrawerOpen }: Props) => {
    const theme = useTheme();

    return (
        <Box>
            <IconButton
                aria-label="open drawer"
                color="inherit"
                data-test="appbar-btn-drawer-open"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                    color: theme.palette.text.primary,
                    ...(open && { display: 'none' })
                }}
            >
                <MenuIcon />
            </IconButton>
        </Box>
    )
}
