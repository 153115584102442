import { useState } from 'react';

// modules
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

// components
import Header from '../../editorScreen/header/Header';

// mui
import {
    Box,
    Button,
    Chip,
    Collapse,
    Container,
    CssBaseline,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Slider,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// actions
import { addVoice, loadConfig, updateVoice } from '../../../../actions/config.actions';

// state
import { possibleStylesFile } from '../../../../state/possibleEmotions';

// interfaces
import { Selector } from "../../../../interfaces/Selector.interface";
import { Voice, VoiceForm as VoiceFormInterface, VoiceToSave } from '../../../../interfaces/article/Voice.interface';
import { ConfigVoices } from '../../../../interfaces/config/Config.interface';


const MainLayout = styled(Box)(({ theme }: any) => ({
    width: 'auto',
    mx: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}));


export const VoiceForm = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    // state selector
    const auth = useSelector((state: Selector) => state.auth);
    const config = useSelector((state: Selector) => state.config.config);
    const role = useSelector((state: Selector) => state.auth.role);
    const languageList = useSelector((state: Selector) => state.languages.languageList);

    const { voice } = useParams() as any;
    const history = useHistory();

    const configVoices = config.filter(c => c.name?.toLowerCase() === 'voices')[0]?.data as ConfigVoices;
    for (const key in configVoices) {
        if (key === "null") delete configVoices[key];
    }

    let activeVoice: Voice | undefined;
    if (configVoices) activeVoice = voice in configVoices ? configVoices[voice] : undefined;

    const [voiceEmotions, setVoiceEmotions] = useState([]);
    const [voiceLanguages, setVoiceLanguages] = useState(activeVoice ? [...activeVoice.languageCodes] : [] as string[]);

    // here we are creating an array with all the emotions, and converting its elements to lower case.
    const availableEmotionsArr = Object.keys(possibleStylesFile)
        .map((k: any, idx: any) => ({
            emotion: possibleStylesFile[k].value?.toLowerCase(),
            tts: possibleStylesFile[k].availableTTS
        }));

    const deepzenEmotionsObj = availableEmotionsArr.filter(element => element.tts.includes('deepzen'));
    const microsoftEmotionsObj = availableEmotionsArr.filter(element => element.tts.includes('microsoft'));
    const cerenceEmotionsObj = availableEmotionsArr.filter(element => element.tts.includes('cerence'));

    // arrays containing available emotions for Deepzen, Microsoft(Denise) and Cerence, respectively.
    const deepzenEmotionsArr = Object.keys(deepzenEmotionsObj).map((k: any, idx: any) => (deepzenEmotionsObj[k].emotion));
    const microsoftEmotionsArr = Object.keys(microsoftEmotionsObj).map((k: any, idx: any) => (microsoftEmotionsObj[k].emotion));
    const cerenceEmotionsArr = Object.keys(cerenceEmotionsObj).map((k: any, idx: any) => (cerenceEmotionsObj[k].emotion));

    // we create an array that contains all of the TTSvoices that are used, and remove the duplicates from it.
    const allTTSvoices = Object.keys(configVoices).map((k: any, idx: any) => (configVoices[k].TTSvoice));
    const availableTTSvoices = [...allTTSvoices];

    // here we create an array that contains all of the TTS that are used, and remove the duplicates from it.
    const allTTS = Object.keys(configVoices).map((k: any, idx: any) => (configVoices[k].TTS));
    const availableTTS: string[] = allTTS.filter((item, index) => allTTS.indexOf(item) === index);

    // we create an object to store all available TTSvoices for each of the TTS
    const voicesPerTTS = {
        google: [] as string[],
        microsoft: [] as string[],
        deepzen: [] as string[],
        cerence: [] as string[]
    };

    // in order to know which belongs to each TTS we use a regex, except for 'Deepzen' TTS.
    availableTTSvoices.map(ttsVoice => {
        if (ttsVoice?.match(/Wavenet|Standard/g)) {
            voicesPerTTS.google.push(ttsVoice);
        } else if (ttsVoice?.match(/Neural/g)) {
            voicesPerTTS.microsoft.push(ttsVoice);
        } else if (ttsVoice?.match(/audrey/g)) {
            voicesPerTTS.cerence.push(ttsVoice);
        } else {
            voicesPerTTS.deepzen.push(ttsVoice);
        }
        return voicesPerTTS;
    })

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    let voiceData: VoiceFormInterface = {
        id: -1,
        name: '',
        TTS: '',
        TTSvoice: '',
        ssmlGender: '',
        pitch: 1,
        speakingRate: 1,
        volume: 1,
        enabled: false,
        description: '',
        automaticVersionEnabled: false,
        emotion: false,
        emotions: [],
        order: '',
        languageCodes: [],
        voiceQuality: null,
        client: false
    }

    if (activeVoice) {
        voiceData = {
            ...voiceData,
            id: activeVoice.id,
            name: activeVoice.name,
            TTS: activeVoice.TTS,
            TTSvoice: activeVoice.TTSvoice,
            ssmlGender: activeVoice.ssmlGender,
            pitch: activeVoice.pitch,
            speakingRate: activeVoice.speakingRate,
            volume: activeVoice.volume,
            enabled: activeVoice.enabled,
            description: activeVoice.description,
            automaticVersionEnabled: activeVoice.automaticVersionEnabled,
            emotion: activeVoice.emotion,
            order: `${activeVoice.order}`,
            languageCodes: [activeVoice.language],
            voiceQuality: activeVoice.voiceQuality,
            client: activeVoice.client?.id ? true : false
        }

        if (activeVoice.emotions) {
            voiceData.emotions = activeVoice.emotions.map(e => e.name);
        }

    }

    // function to handle emotions selection
    const handleEmotionsChange = (e: any) => {
        const { target: { value } } = e;

        setVoiceEmotions(typeof value === 'string' ? value.split(',') : value);
    };

    // function to handle languages selection
    const handleLanguagesChange = (e: any) => {
        const { target: { value } } = e;

        setVoiceLanguages(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSubmit = (values: VoiceFormInterface) => {
        const voiceId = values.id;
        const voiceToSave: VoiceToSave = {
            automaticVersionEnabled: values.automaticVersionEnabled,
            description: values.description,
            emotion: values.emotion,
            enabled: values.enabled,
            name: values.name,
            pitch: values.pitch,
            speakingRate: values.speakingRate,
            ssmlGender: values.ssmlGender,
            TTS: values.TTS,
            TTSvoice: values.TTSvoice,
            volume: values.volume,
            voiceQuality: values.voiceQuality,
            order: parseInt(values.order),
            clientId: values.client ? auth.uid : null,
            languageCodes: values.languageCodes,
            emotions: values.emotions
        }

        // if there is an activeVoice, update the voice
        if (activeVoice) {
            // update voice
            updateVoice(voiceId, voiceToSave).then(action => dispatch(action));

        } else {
            // add new voice
            addVoice(voiceToSave).then(action => dispatch(action));
        }
    }

    const voiceValidationShape = {
        name: yup
            .string()
            .min(4, 'Voice name should be of minimum 4 characters length')
            .required('Voice name is required'),
        TTS: yup
            .string()
            .required('TTS is required'),
        TTSvoice: yup
            .string()
            .required('TTS voice is required'),
        ssmlGender: yup
            .string()
            .required('Gender is required'),
        voiceQuality: yup
            .string()
            .required('Voice quality is required'),
        pitch: yup
            .number(),
        speakingRate: yup
            .number(),
        volume: yup
            .number(),
        description: yup
            .string(),
        enabled: yup
            .bool()
            .required('Select valid voice status'),
        automaticVersionEnabled: yup
            .bool()
            .required('Select valid automatic version'),
        emotion: yup
            .bool()
            .required('Select valid emotion status'),
        order: yup
            .number()
            .min(1, 'Min value is 1')
            .max(999, 'Max value is 999')
            .required('Order is required'),
        languageCodes: yup
            .array()
            .required('At least one language is required'),
        client: yup
            .bool()
            .required('Select client specific')
    };

    const validationSchema = yup.object().shape(voiceValidationShape);

    const emotions = { emotions: voiceEmotions };
    const languageCodes = { languageCodes: voiceLanguages };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: voiceData,
        validationSchema,
        onSubmit: (values) => {
            values = { ...values, ...emotions, ...languageCodes };

            handleSubmit(values);
            loadConfig().then(action => dispatch(action));
            history.goBack();
        },
    });


    return (
        <>
            <CssBaseline />
            <Container maxWidth="lg">
                <Header title="Voice Index management" />
                <MainLayout>
                    <Container maxWidth="lg">
                        <Typography component='div' variant='h5' color='primary'>
                            {activeVoice ? voice : 'New Voice'}
                        </Typography>
                    </Container>
                    <Paper
                        sx={{
                            background: theme.palette.secondary.light,
                            borderRadius: 8,
                            p: theme.spacing(5),
                            [theme.breakpoints.up(600 + parseInt(theme.spacing(3)) * 2)]: {
                                my: theme.spacing(3),
                                p: theme.spacing(5),
                            }
                        }}
                    >
                        <form onSubmit={formik.handleSubmit} autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Voice name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.name && formik.errors.name}
                                        fullWidth
                                        sx={{ background: theme.palette.background.default }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl
                                        sx={{
                                            minWidth: 120,
                                            visibility: (role === 'SuperAdmin') ? 'visible' : 'hidden'
                                        }}
                                    >
                                        <InputLabel id="TTSLabel">TTS</InputLabel>
                                        <Select
                                            error={formik.touched.TTS && Boolean(formik.errors.TTS)}
                                            id="TTS"
                                            label="TTS"
                                            labelId="TTSLabel"
                                            name="TTS"
                                            onBlur={formik.handleBlur}
                                            onChange={(e) => { formik.handleChange(e); setVoiceEmotions([]) }}
                                            value={formik.values.TTS}
                                            sx={{ background: theme.palette.background.default }}
                                        >
                                            <MenuItem value="">
                                                <em>Select</em>
                                            </MenuItem>
                                            {
                                                availableTTS.map(tts => (
                                                    <MenuItem key={tts} value={tts}>
                                                        {tts}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                        {
                                            formik.errors.TTS && formik.touched.TTS &&
                                            <div style={{
                                                color: '#f44336',
                                                fontSize: '0.75rem',
                                                paddingTop: '5px'
                                            }}>
                                                {formik.errors.TTS}
                                            </div>
                                        }
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        id="TTSvoice"
                                        name="TTSvoice"
                                        label="TTS Voice name"
                                        value={formik.values.TTSvoice}
                                        onChange={formik.handleChange}
                                        error={formik.touched.TTSvoice && Boolean(formik.errors.TTSvoice)}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.TTSvoice && formik.errors.TTSvoice}
                                        fullWidth
                                        sx={{
                                            background: theme.palette.background.default,
                                            visibility: (role === 'SuperAdmin') ? 'visible' : 'hidden'
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl sx={{ minWidth: 320 }}>
                                        <InputLabel id="languageLabel">Languages</InputLabel>
                                        <Select
                                            error={formik.touched.languageCodes && Boolean(formik.errors.languageCodes)}
                                            id="languages"
                                            name="languages"
                                            label="Languages"
                                            labelId="languageLabels"
                                            onBlur={formik.handleBlur}
                                            onChange={(e) => handleLanguagesChange(e)}
                                            value={voiceLanguages}
                                            multiple
                                            renderValue={selected => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {
                                                        selected.map(value => (<Chip key={value} label={value} />))
                                                    }
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                            sx={{ background: theme.palette.background.default }}
                                        >
                                            {
                                                languageList
                                                    .sort((a, b) => a.code < b.code ? -1 : 1)
                                                    .map(language => (
                                                        <MenuItem key={language.code} value={language.code}>
                                                            {language.code}
                                                        </MenuItem>
                                                    ))
                                            }
                                        </Select>
                                        {
                                            formik.errors.languageCodes && formik.touched.languageCodes &&
                                            <div style={{
                                                color: '#f44336',
                                                fontSize: '0.75rem',
                                                paddingTop: '5px'
                                            }}>
                                                {formik.errors.languageCodes}
                                            </div>
                                        }
                                    </FormControl>
                                </Grid>

                                <Grid item xs={2}>
                                    <FormControl sx={{ minWidth: 120 }}>
                                        <InputLabel id="ssmlGenderLabel">Gender</InputLabel>
                                        <Select
                                            error={formik.touched.ssmlGender && Boolean(formik.errors.ssmlGender)}
                                            id="ssmlGender"
                                            label='Gender'
                                            labelId='ssmlGenderLabel'
                                            name="ssmlGender"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.ssmlGender}
                                            sx={{ background: theme.palette.background.default }}
                                        >
                                            <MenuItem value=""><em>Select</em></MenuItem>
                                            <MenuItem value={'MALE'}>Male</MenuItem>
                                            <MenuItem value={'FEMALE'}>Female</MenuItem>
                                        </Select>
                                        {
                                            formik.errors.ssmlGender && formik.touched.ssmlGender &&
                                            <div style={{
                                                color: '#f44336',
                                                fontSize: '0.75rem',
                                                paddingTop: '5px'
                                            }}>
                                                {formik.errors.ssmlGender}
                                            </div>
                                        }
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl sx={{ minWidth: 240 }}>
                                        <InputLabel id="voiceQualityLabel">Voice Quality</InputLabel>
                                        <Select
                                            error={formik.touched.voiceQuality && Boolean(formik.errors.voiceQuality)}
                                            id="voiceQuality"
                                            label='Voice Quality'
                                            labelId='voiceQualityLabel'
                                            name="voiceQuality"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}

                                            value={formik.values.voiceQuality}
                                            sx={{ background: theme.palette.background.default }}
                                        >
                                            <MenuItem value=""><em>Select</em></MenuItem>
                                            <MenuItem value={"LOW"}>Low</MenuItem>
                                            <MenuItem value={"MEDIUM"}>Medium</MenuItem>
                                            <MenuItem value={"PREMIUM"}>Premium</MenuItem>
                                        </Select>
                                        {
                                            formik.errors.voiceQuality && formik.touched.voiceQuality &&
                                            <div style={{
                                                color: '#f44336',
                                                fontSize: '0.75rem',
                                                paddingTop: '5px'
                                            }}>
                                                {formik.errors.voiceQuality}
                                            </div>
                                        }
                                    </FormControl>
                                </Grid>

                                <Grid item xs={2}>
                                    <FormControl sx={{ minWidth: 120 }}>
                                        <TextField
                                            error={formik.touched.order && Boolean(formik.errors.order)}
                                            id="order"
                                            InputProps={{ inputProps: { type: 'number', pattern: "[0-9]*", max: 999 } }}
                                            label="Order"
                                            name="order"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.order}
                                            sx={{ background: theme.palette.background.default }}
                                        />
                                        {
                                            formik.errors.order && formik.touched.order &&
                                            <div style={{
                                                color: '#f44336',
                                                fontSize: '0.75rem',
                                                paddingTop: '5px'
                                            }}>
                                                {formik.errors.order}
                                            </div>
                                        }
                                    </FormControl>
                                </Grid>
                                { formik.values.TTS !== 'elevenlabs' &&(
                                    <Grid item xs={12} >
                                        <InputLabel id="pitch">Pitch</InputLabel>
                                        <Slider
                                            aria-label='slider-pitch'
                                            defaultValue={formik.values.pitch}
                                            id="pitch"
                                            max={2}
                                            min={-2}
                                            name="pitch"
                                            onBlur={formik.handleBlur}
                                            step={0.01}
                                            value={formik.values.pitch}
                                            onChange={(e: any, v: any) => {
                                                formik.setFieldValue("pitch", v);
                                            }}
                                            marks={[
                                                {
                                                    value: -2,
                                                    label: "-2",
                                                },
                                                {
                                                    value: -1,
                                                    label: "-1",
                                                },
                                                {
                                                    value: 0,
                                                    label: "0",
                                                },
                                                {
                                                    value: 1,
                                                    label: "1",
                                                },
                                                {
                                                    value: 2,
                                                    label: "2",
                                                }
                                            ]}
                                            valueLabelDisplay="auto"
                                        />
                                    </Grid>

                                )}
                                { formik.values.TTS !== 'elevenlabs' &&(
                                    <Grid item xs={12}>
                                        <InputLabel id="speakingRate">Speaking Rate</InputLabel>
                                        <Slider
                                            id="speakingRate"
                                            name="speakingRate"
                                            value={formik.values.speakingRate}
                                            onChange={(e, v) => {
                                                formik.setFieldValue("speakingRate", v);
                                            }}
                                            onBlur={formik.handleBlur}
                                            defaultValue={formik.values.speakingRate}
                                            step={0.01}
                                            min={0}
                                            max={2}
                                            marks={[
                                                {
                                                    value: 0,
                                                    label: "0",
                                                },
                                                {
                                                    value: 0.5,
                                                    label: "0.5",
                                                },
                                                {
                                                    value: 1,
                                                    label: "1",
                                                },
                                                {
                                                    value: 1.5,
                                                    label: "1.5",
                                                },
                                                {
                                                    value: 2,
                                                    label: "2",
                                                },
                                            ]}
                                            valueLabelDisplay="auto"
                                        />
                                    </Grid>
                                )}
                                { formik.values.TTS !== 'elevenlabs' &&(
                                    <Grid item xs={12}>
                                        <InputLabel id="volume">Volume</InputLabel>
                                        <Slider
                                            id="volume"
                                            name="volume"
                                            value={formik.values.volume}
                                            onChange={(e, v) => { formik.setFieldValue("volume", v) }}
                                            onBlur={formik.handleBlur}
                                            defaultValue={formik.values.volume}
                                            step={0.01}
                                            min={0}
                                            max={2}
                                            marks={[
                                                {
                                                    value: 0,
                                                    label: "0",
                                                },
                                                {
                                                    value: 0.5,
                                                    label: "0.5",
                                                },
                                                {
                                                    value: 1,
                                                    label: "1",
                                                },
                                                {
                                                    value: 1.5,
                                                    label: "1.5",
                                                },
                                                {
                                                    value: 2,
                                                    label: "2",
                                                },
                                            ]}
                                            valueLabelDisplay="auto"
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={3}>
                                    <InputLabel id="enabled">Status</InputLabel>
                                    <FormControlLabel sx={{ minWidth: 120 }}
                                        id={voice}
                                        control={
                                            <Switch
                                                sx={{
                                                    '& .MuiIconButton-label': {
                                                        justifyContent: 'flex-start',
                                                    }
                                                }}
                                                checked={formik.values.enabled} name="enabled"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                inputProps={{ 'aria-label': 'controlled' }} />}
                                        label={formik.values.enabled ? 'Enabled' : 'Disabled'}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={3}
                                    sx={{ visibility: (role === 'SuperAdmin') ? 'visible' : 'hidden' }}
                                >
                                    <InputLabel id="client">Client specific</InputLabel>
                                    <FormControlLabel sx={{ minWidth: 120 }}
                                        id={voice}
                                        control={
                                            <Switch
                                                sx={{
                                                    '& .MuiIconButton-label': {
                                                        justifyContent: 'flex-start',
                                                    }
                                                }}
                                                checked={formik.values.client} name="client"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                inputProps={{ 'aria-label': 'controlled' }} />
                                        }
                                        label={formik.values.client ? 'Yes' : 'No'}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={3}
                                    sx={{ visibility: (role === 'SuperAdmin') ? 'visible' : 'hidden' }}
                                >
                                    <InputLabel id="automaticVersionEnabled">Automatic Version</InputLabel>
                                    <FormControlLabel sx={{ minWidth: 120 }}
                                        id={voice}
                                        control={
                                            <Switch
                                                sx={{
                                                    '& .MuiIconButton-label': {
                                                        justifyContent: 'flex-start',
                                                    }
                                                }}
                                                checked={formik.values.automaticVersionEnabled}
                                                name="automaticVersionEnabled"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={formik.values.automaticVersionEnabled ? 'Enabled' : 'Disabled'}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <InputLabel id="emotion">Emotion</InputLabel>
                                    {
                                        formik.values.TTS === 'deepzen' ||
                                            formik.values.TTSvoice === 'fr-FR-DeniseNeural' ||
                                            formik.values.TTS === 'cerence'
                                            ? <FormControlLabel sx={{ minWidth: 120 }}
                                                id={voice}
                                                control={
                                                    <Switch
                                                        sx={{
                                                            '& .MuiIconButton-label': {
                                                                justifyContent: 'flex-start',
                                                            }
                                                        }}
                                                        checked={formik.values.emotion}
                                                        name="emotion"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                }
                                                label={formik.values.emotion ? 'Enabled' : 'Disabled'}
                                            />
                                            : <FormControlLabel disabled sx={{ minWidth: 120 }}
                                                id={voice}
                                                control={
                                                    <Switch
                                                        sx={{
                                                            '& .MuiIconButton-label': {
                                                                justifyContent: 'flex-start',
                                                            }
                                                        }}
                                                        checked={false} name="emotion"
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                }
                                                label={'Disabled'}
                                            />
                                    }
                                </Grid>

                                <Grid item xs={12}>
                                    <Collapse in={formik.values.emotion ? true : false}>
                                        <FormControl sx={{ minWidth: 120 }}>
                                            <InputLabel id="emotionsLabel">Emotions</InputLabel>
                                            <Select
                                                id="emotionsSelector"
                                                multiple
                                                name="emotion"
                                                value={voiceEmotions}
                                                onChange={handleEmotionsChange}
                                                input={<OutlinedInput id='emotionsLabel' label='Emotions' />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} >
                                                        {selected.map(value => (<Chip key={value} label={value} />))}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem disabled value="">
                                                    <em>Select</em>
                                                </MenuItem>

                                                {
                                                    formik.values.TTS === 'deepzen'
                                                        ? deepzenEmotionsArr.map(emotion => (
                                                            <MenuItem key={emotion} value={emotion} >
                                                                {emotion}
                                                            </MenuItem>
                                                        ))
                                                        : formik.values.TTSvoice === 'fr-FR-DeniseNeural'
                                                            ? microsoftEmotionsArr.map(emotion => (
                                                                <MenuItem key={emotion} value={emotion} >
                                                                    {emotion}
                                                                </MenuItem>
                                                            ))
                                                            : formik.values.TTS === 'cerence'
                                                                ? cerenceEmotionsArr.map(emotion => (
                                                                    <MenuItem key={emotion} value={emotion} >
                                                                        {emotion}
                                                                    </MenuItem>
                                                                ))
                                                                : null
                                                }
                                            </Select>
                                            {
                                                !voiceEmotions.length &&
                                                <Box sx={{
                                                    color: '#f44336',
                                                    fontSize: '0.75rem',
                                                    pt: 1
                                                }}>
                                                    Select at least one emotion
                                                </Box>
                                            }
                                        </FormControl>
                                    </Collapse>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="description"
                                        name="description"
                                        label="Description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        error={formik.touched.description && Boolean(formik.errors.description)}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        autoComplete="new-description"
                                        sx={{ background: theme.palette.background.default }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={(!voiceEmotions.length && formik.values.emotion) || !voiceLanguages.length}
                                    >
                                        {activeVoice ? 'Save' : 'Create'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>

                </MainLayout>

            </Container >
        </>
    );
}
