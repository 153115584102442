import { AnyAction } from 'redux';
import { types } from '../actions/actionTypes/actionTypes'
import { SocketState } from '../interfaces/Selector.interface';

const currentDate = new Date();

const initialState: SocketState = {
    lastArticleListUpdate: currentDate.toISOString(),
    newArticleAudioGeneration: -1,
    newArticleVideoGeneration: -1,
}

export const socketReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case types.socketArticleListUpdated:
            return {
                ...state,
                lastArticleListUpdate: action.payload.data as string
            } as SocketState

        case types.socketNewArticleAudioGenerated:
            return {
                ...state,
                newArticleAudioGeneration: action.payload.data as number
            } as SocketState

        case types.socketNewArticleVideoGenerated:
            return {
                ...state,
                newArticleVideoGeneration: action.payload.data as number
            } as SocketState

        default:
            return state as SocketState
    }
}