import { useContext, useState } from "react";

// modules
import { useSelector } from 'react-redux';

// custom components
import { ImpersonateSelector } from "./impersonateSelector/ImpersonateSelector";
import { LogoutButton } from "../../../header/logoutButton/LogoutButton";
import { MenuElement } from "./menuElement/MenuElement";
import { ThemeSwitch } from "../../../../../generics/ThemeSwitch";

// mui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Divider,
    FormControlLabel,
    IconButton,
    Menu,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ConfigIcon from '@mui/icons-material/Settings';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';

// interfaces
import { Selector } from '../../../../../../interfaces/Selector.interface';

// theme context
import { ColorModeContext } from "../../../../../../App";
import { capitalizeFirstLetter } from "../../../../../../helpers/capitalizeFirstLetter";


function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    const initials = name.split(' ').length > 1
        ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        : name.charAt(0);

    return {
        sx: { bgcolor: stringToColor(name) },
        children: initials,
    };
}

const settingsMenuElements = [
    {
        label: 'Config',
        icon: ConfigIcon,
        isRoleProtected: true
    },
    {
        label: 'Files',
        icon: InsertDriveFile,
        isRoleProtected: false
    },
    {
        label: 'Users',
        icon: PeopleAltIcon,
        isRoleProtected: true
    }
]


const appVersion = "v2.15.0";


export const SettingsMenu = () => {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);

    // state selector
    const { impersonatedBy, impersonator, name, role } = useSelector((state: Selector) => state.auth);
    const usersList = useSelector((state: Selector) => state.users.usersList);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenConfigMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const getUserName = (userId: number): string => {
        const usersFiltered = usersList.filter(u => u.id === userId);
        if (usersFiltered) return usersFiltered[0]?.name || '';
        return '';
    }


    return (
        <>
            <Tooltip title="Settings Panel">
                <IconButton
                    data-test="settingsmenu-btn-open"
                    onClick={handleOpenConfigMenu}
                    sx={{ p: 0 }}
                >
                    <Avatar alt={name.toUpperCase()} {...stringAvatar(name.toUpperCase())} />
                </IconButton>
            </Tooltip>

            <Menu
                id="settings-menu-appbar"
                data-test="settingsmenu-component"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                sx={{ mt: '45px' }}
            >
                <Box sx={{ m: 1, px: 3, py: 2 }}>
                    <Stack direction='row' spacing={2}>
                        <Avatar alt={name.toUpperCase()} {...stringAvatar(name.toUpperCase())} />
                        <Stack
                            direction='column'
                            display='flex'
                            justifyContent='center'
                        >
                            <Typography
                                data-test="settingsmenu-label-username"
                                variant="body1"
                                fontWeight={600}
                                color={theme.palette.text.secondary}
                            >
                                {capitalizeFirstLetter(name)}
                            </Typography>
                            <Typography
                                data-test="settingsmenu-label-userrole"
                                variant="caption"
                                color={theme.palette.text.secondary}
                            >
                                {role}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>

                {
                    impersonatedBy > 0
                    &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Typography
                            data-test="settingsmenu-label-impersonator"
                            variant="caption"
                            color={theme.palette.text.secondary}
                        >
                            Impersonator: <b>{getUserName(impersonatedBy)}</b>
                        </Typography>
                    </Box>
                }

                {
                    ((role === "SuperAdmin" && impersonator) || impersonatedBy > 0)
                    &&
                    <ImpersonateSelector />
                }

                <Divider sx={{ my: 1 }} />

                {
                    settingsMenuElements.map(e => (
                        (
                            !e.isRoleProtected ||
                            (e.isRoleProtected && role.toLowerCase().includes('admin'))
                        ) &&
                        <MenuElement
                            key={`setting-menu-element-${e.label}`}
                            label={e.label}
                            Icon={e.icon}
                            handleClose={handleCloseUserMenu}
                        />
                    )
                    )
                }

                <LogoutButton buttonType="menuItem" />

                <Divider />

                <Box sx={{ mt: 1.5, mb: 0.5, py: 1 }}>
                    <Stack
                        direction='column'
                        spacing={3}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <FormControlLabel
                            onClick={colorMode.toggleColorMode}
                            control={<ThemeSwitch defaultChecked={theme.palette.mode === 'dark'} />}
                            label={
                                <Typography
                                    component='span'
                                    variant="body2"
                                    noWrap
                                    sx={{ color: theme.palette.text.secondary }}
                                >
                                    {theme.palette.mode === 'dark' ? 'Dark Mode' : 'Light Mode'}
                                </Typography>
                            }
                        />

                        <Typography
                            component='span'
                            variant="body2"
                            noWrap
                            sx={{ color: theme.palette.text.secondary }}
                        >
                            Odialab {appVersion}
                        </Typography>

                    </Stack>

                </Box>

            </Menu>
        </>
    )
}
