import { AnyAction } from 'redux';

// types
import { types } from './actionTypes/actionTypes';

// action creator
import { actionCreator } from './actionCreator/actionCreator';


export const articleListUpdated = (): AnyAction => {
    const currentDate = new Date();
    return actionCreator(types.socketArticleListUpdated, currentDate.toISOString())
}

export const newArticleAudioGenerated = (articleId: number): AnyAction => {
    return actionCreator(types.socketNewArticleAudioGenerated, articleId)
}

export const newArticleVideoGenerated = (articleId: number): AnyAction => {
    return actionCreator(types.socketNewArticleVideoGenerated, articleId)
}