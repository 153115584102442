import { textTags } from "../../../state/textTags";
import { escapeRegExp } from "../../escapeRegExp";
import { sanitizeLexicon } from "./sanitizeLexicon";

// interfaces
import { LexiconEntry } from "../../../models/lexiconEntry.model";
import { FlagSyntaxErrorReturn } from "../../../interfaces/FlagParts.interface";
import { ArticlePart } from "../../../interfaces/article/ArticlePart.interface";
import { Voice } from "../../../interfaces/article/Voice.interface";


export const flagArticleParts = (parts: any, syntaxErrors: string[], lexicon: LexiconEntry[]): ArticlePart[] => {
    const flaggedParts: ArticlePart[] = [];
    for (const part of parts) {
        const flaggedPart = flagPart(part.content, part, syntaxErrors, lexicon);
        flaggedParts.push(flaggedPart);
    }
    return flaggedParts;
}


export const flagPart = (text: string, part: ArticlePart, syntaxErrors: string[], lexicon: LexiconEntry[]): ArticlePart => {
    let flaggedText: string = `${text}`;
    let syntaxError = false;

    if (syntaxErrors.length > 0) {
        let flagResult: FlagSyntaxErrorReturn = flagSyntaxErrors(
            text,
            syntaxErrors
        );
        flaggedText = flagResult.text;
        syntaxError = flagResult.hasError;
    }

    const voiceLanguage = part.voice?.language || '';
    flaggedText = setTextLexiconTags(flaggedText, part.voice, lexicon, voiceLanguage);

    part.content = deleteHighlightTags(text);
    part.contentHtml = flaggedText;
    part.contentHtmlOriginal = flaggedText;
    part.hasSyntaxError = syntaxError;

    return part;
}


/**
 * Function that checks user input so as to flag syntax errors. This way, if there were any,
 * we can disable buttons and actions until errors are deleted from input text.
 */
export const flagSyntaxErrors = (
    inputText: string,
    syntaxErrorsList: string[]
): FlagSyntaxErrorReturn => {
    let flagSyntaxResult: FlagSyntaxErrorReturn = {
        text: inputText,
        hasError: false
    }

    try {
        const badSyntaxTag = textTags.filter(element => element.id === "bad-syntax");

        /* RegExp used to catch all span tags that begin with <span name="..." ...> </span ....>
        including ITS content. */
        const spanTags = /<span name[\s\S]*?>[\s\S]*?<\/span>/g; // eslint-disable-line

        // Dividing text into pieces WITHOUT spanTags, if there where any.
        let spanMatches: any = "";
        let dividedText: any = "";
        if (spanTags.test(inputText)) {
            spanMatches = inputText.match(spanTags);
            dividedText = inputText.split(spanTags);
        };

        // Creating a regular expression out of every syntax error, and flagging it.
        // Also flagging syntax error in redux editorPage's state.
        for (let syntaxError of syntaxErrorsList) {
            const scapedSyntaxError = escapeRegExp(syntaxError);
            const localRegExp = new RegExp(`${scapedSyntaxError}`, 'g');

            // Looking for each piece of the text that has been previously divided, and replacing
            // content in its position.
            if (dividedText.length) {
                dividedText.forEach((pieceOfText: string, index: number) => {
                    if (localRegExp.test(pieceOfText)) {
                        dividedText[index] = pieceOfText.replace(
                            localRegExp,
                            badSyntaxTag[0]?.html?.replace("PLACEHOLDER", `${syntaxError}`)
                        );
                        // dispatchCallback(actionCallback(true, partNumber));
                        flagSyntaxResult.hasError = true;
                    };
                });
            }
            else {
                // If there hasn't been any divisions, then we will just replace the corresponding acronyms
                if (localRegExp.test(inputText)) {
                    flagSyntaxResult.text = inputText.replace(
                        localRegExp,
                        badSyntaxTag[0]?.html?.replace("PLACEHOLDER", `${syntaxError}`)
                    );
                    // dispatchCallback(actionCallback(true, partNumber));
                    flagSyntaxResult.hasError = true;
                };
            };
        };

        // If this text has been previously divided, then we will now put it together.
        if (dividedText.length) {
            spanMatches.forEach((span: any, index: number) => {
                dividedText[index] = dividedText[index] + span;
            });
            flagSyntaxResult.text = dividedText.join("");
        };

        // Checking for already flagged syntax errors
        let localValidation = true;

        for (let syntaxError of syntaxErrorsList) {
            const scapedSyntaxError = escapeRegExp(syntaxError);
            const localRegExp = new RegExp(`${scapedSyntaxError}`);
            if (localRegExp.test(inputText)) localValidation = false;
        };

        // Lifting result to redux.
        if (localValidation) {
            // dispatchCallback(actionCallback(false, partNumber));
            flagSyntaxResult.hasError = false;
        };

        return flagSyntaxResult;
    }
    catch (error) {
        console.error('Couldn\'t flag error in input text. Returning original text.', error);
        return flagSyntaxResult;
    };
};

// set lexicon tags on all the parts
export const setPartsLexiconTags = (parts: ArticlePart[], lexicon: LexiconEntry[]) => {
    let highlightedParts: ArticlePart[] = [];

    parts.forEach((part, index) => {
        const contentHtml = part.contentHtml;
        const voice = part.voice;
        const language = part.voice.language;

        const articlePart: ArticlePart = {
            ...part,
            contentHtml: setTextLexiconTags(contentHtml, voice, lexicon, language)
        }
        highlightedParts.push(articlePart);
    });

    return highlightedParts;
};

// set lexicon tags on a text part
export const setTextLexiconTags = (text: string, voice: Voice, lexicon: LexiconEntry[], language: string): string => {
    try {
        // get lexicon html tag
        const flagLexiconTag = textTags.filter(element => element.id === "flag-lexicon")[0];
        if (!flagLexiconTag) return text;

        // clean existing lexicon tags
        let cleanText = deleteTags(text, "flag-lexicon");

        // get lexicon of the current language
        let languageLexicon = lexicon.filter(lexicon => lexicon.language === language);

        // remove tts dependant lexicon entries from other tts than current voice's tts
        languageLexicon = languageLexicon.filter(lexicon =>
            !lexicon.isTtsDependant || (lexicon.isTtsDependant && lexicon.tts === voice.TTS)
        );

        // filter lexicon matches with current text
        const lexiconMatches = languageLexicon.filter(l => cleanText.includes(l.originalText));
        if (lexiconMatches.length === 0) return cleanText;

        let highlightedText = `${cleanText}`;
        for (const lexicon of lexiconMatches) {
            // avoid issue with "*" entry. it was provoking an invalid regex expression
            const lexiconValue = lexicon.originalText.replace('*', '');
            const lexiconReplacement = lexicon.translationText;

            // delete potential surrounding tags
            highlightedText = highlightedText.replace(
                new RegExp(`(<span [^>]+>)(?<taggedText>[^<]*${lexiconValue}[^<]*)(</span>)`, "g"),
                "$<taggedText>"
            );


            if (highlightedText) {
                const lexiconTagHtml = flagLexiconTag.html
                    .replace("PLACEHOLDER", `${lexiconValue}`)
                    .replace("TOOLTIP", `${lexiconReplacement}`);

                // sanitize lexicon value: remove reserver characters (i.e. "+", "*")
                const lexiconValueSanitized = sanitizeLexicon(lexiconValue);

                // regex expression to match the lexicon value as a word
                // that means it can't be surrounded by any letter or number
                // it will be surrounded only by spaces or punctuation marks
                const lexiconRegex = new RegExp(
                    `(?<![0-9a-zA-Z\u00c0-\u024f\u1e00-\u1eff])${lexiconValueSanitized}(?![0-9a-zA-Z\u00c0-\u024f\u1e00-\u1eff])`,
                    "gmu"
                )

                highlightedText = highlightedText?.replace(
                    lexiconRegex,
                    lexiconTagHtml
                );
            }
        }

        return highlightedText;

    } catch (error) {
        return text;
    }
};

// delete a defined span tag type from a text
export const deleteTags = (text: string, tag: string) => {
    if (!text) return text;
    return text.replace(
        new RegExp(`(<span name="${tag}[^>]+>)(?<taggedText>[^<]+)(</span>)`, "g"), "$<taggedText>")
}

// delete highlighted tags from a text
export const deleteHighlightTags = (text: string) => {
    text = deleteTags(text, "flag-word");
    text = deleteTags(text, "flag-liaison");
    text = deleteTags(text, "flag-lexicon");
    return text;
}
