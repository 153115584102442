import React from "react";

// mui
import { Box, Button, Grid, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import TagIcon from "@mui/icons-material/Tag";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import DeleteIcon from "@mui/icons-material/Delete";
import Visibility from "@mui/icons-material/Visibility";
import ViewList from "@mui/icons-material/ViewList";

// redux
import { useDispatch, useSelector } from "react-redux";

// interfaces
import { Playlist } from "../../../../../interfaces/Playlist.interface";
import { Article } from "../../../../../interfaces/article/Article.interface";
import { Selector } from "../../../../../interfaces/Selector.interface";

// actions
import { loadPlaylists } from "../../../../../actions/playlist.action";

// helpers
import api from "../../../../../helpers/services/api";

// components
import { ButtonsGrid, ContentGrid, TitleBox } from "../../audioEditorPage/styles/EditorPageStyle";
import { PlaylistRightPanel } from "../rightPanel/PlaylistRightPanel";
import { PlaylistContentCell } from "./playlistContentCell/PlaylistContentCell";

// libs
import Swal from "sweetalert2";


interface Props {
    playlist: Playlist
}

export const PlaylistContent = React.memo(({ playlist }: Props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const articles: Article[] = useSelector((state: Selector) => state.files);

    const handleDeletePlaylist = async (playlistId: number) => {
        await api.playlist.delete(playlistId).then(resp => {
            if (resp.status === 200 || resp.status === 201) {
                Swal.fire('Success', 'Article successfully removed from playlist', 'success');
                loadPlaylists().then(action => dispatch(action))
            } else {
                Swal.fire('Error', `There was a problem trying to remove article from the playlist`, 'error');
            }
        })
    }

    const handleOpenPlaylist = () => {
        if (playlist && playlist.articles && playlist.articles.length > 0) {

            api.client.getName().then(async (resp: any) => {
                const clientName: string = (await resp.json()).clientName;
                if (resp.ok) {
                    const language = playlist.defaultLanguageCode;
                    const playerEnv = process.env.REACT_APP_ODIA_PLAYER_URL;
                    const url = `${playerEnv}/${language}/${clientName}/playlist/${encodeURIComponent(playlist.name)}?type=large&theme=${theme.palette.mode}`;
                    window.open(url);
                } else {
                    throw new Error('Errors recovering client name');
                }
            });
        }
    }

    return (
        <Grid container>
            <Grid item xs={9} sm={9}>
                <ButtonsGrid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>

                        <Button
                            data-test="playlisteditor-btn-show"
                            size="medium"
                            sx={{ mb: "8px", ml: "5px" }}
                            startIcon={<Visibility fontSize="small" />}
                            onClick={() => handleOpenPlaylist()}
                            variant="outlined"
                        >
                            Show Playlist
                        </Button>

                        <Button
                            data-test="playlisteditor-btn-delete"
                            size="medium"
                            sx={{ mb: "8px", ml: "5px" }}
                            startIcon={<DeleteIcon fontSize="small" />}
                            onClick={() => handleDeletePlaylist(playlist.id)}
                            variant="outlined"
                            color="secondary"
                        >
                            Delete Playlist
                        </Button>

                        <TitleBox>
                            <Stack direction='row'>
                                <Tooltip title="Article's ID">
                                    <Stack direction='row'>
                                        <TagIcon fontSize="medium" />
                                        <Typography variant='body1'>
                                            {playlist.id}
                                        </Typography>
                                    </Stack>
                                </Tooltip>

                                <Tooltip title="Playlist's name">
                                    <Stack direction='row'>
                                        <NewspaperIcon fontSize="medium" sx={{ ml: 1, mr: 0.5 }} />
                                        <Typography
                                            data-test="playlistinfo-label-name"
                                            variant='body1'
                                        >
                                            {playlist.name}
                                        </Typography>
                                    </Stack>
                                </Tooltip>

                                <Tooltip title="Playlist's length">
                                    <Stack direction='row'>
                                        <ViewList fontSize="medium" sx={{ ml: 1, mr: 0.5 }} />
                                        <Typography
                                            data-test="playlistinfo-label-length"
                                            variant='body1'
                                        >
                                            {playlist.articles.length}
                                        </Typography>
                                    </Stack>
                                </Tooltip>
                            </Stack>
                        </TitleBox>

                    </Grid>
                </ButtonsGrid>

                <ContentGrid container m={4} pt={5}>
                    <Stack direction='column' spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <Box sx={{ ml: '10px' }}>
                            <Stack
                                direction='row'
                                spacing={{ md: 5 }}
                            >
                                <Typography
                                    data-test="playlistcontent-label-title"
                                    sx={{ display: 'block', fontSize: '18px', fontWeight: 'bold', width: '24vw' }}
                                >
                                    Title
                                </Typography>
                                <Typography
                                    data-test="playlistcontent-label-date-title"
                                    sx={{ display: 'block', fontSize: '18px', fontWeight: 'bold', width: '8vw' }}
                                >
                                    Name
                                </Typography>
                                <Typography
                                    data-test="playlistcontent-label-date-title"
                                    sx={{ display: 'block', fontSize: '18px', fontWeight: 'bold', }}
                                >
                                    Creation Date
                                </Typography>
                            </Stack>
                        </Box>

                        {
                            playlist.articles.map(article => {
                                const fullArticle: Article = articles.find(a => a.id === article.id) || article;
                                return (
                                    <PlaylistContentCell
                                        key={article.id}
                                        playlist={playlist}
                                        article={fullArticle}
                                    />
                                )
                            })
                        }
                    </Stack>
                </ContentGrid>
            </Grid >

            <PlaylistRightPanel />

        </Grid >)
})